import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Logo from '../img/logo_white.png';


export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{ display: 'flex', bgcolor: 'grey', flexDirection:'column' }}>
      <Container sx={{ my: 8, display: 'flex' }}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={4} md={3}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              spacing={2}
              sx={{ height: 120}}
            >
              <Grid item >

                <div style={{ display: 'flex', marginBottom:17}}>
                <LocationOnIcon style={{ color:'#fff',marginRight:7}}/>
                <Typography style={{ color:'#fff'}}>
                Suttehauserstraße.124
                49080 Osnabrück
                </Typography>
                </div>

                <div style={{ display: 'flex'}}>
                <PhoneEnabledIcon style={{ color:'#fff',marginRight:7}}/>
                <a data-global="phone" href="tel:+4917684752988" data-track-event="click" data-track-action="phone_link"
                style={{textDecoration: 'none', color:'#fff'}}>+49 17684752988</a>
                </div>
                
                <div style={{ display: 'flex'}}>
                <EmailIcon style={{ color:'#fff', marginRight:7}}/>
                <a href="mailto:roj.bau49@gmail.com" data-track-event="click" data-track-action="email_link" 
                style={{textDecoration: 'none', color:'#fff'}}>roj.bau49@gmail.com</a>
                </div>

            </Grid>
          </Grid>
          </Grid>
        </Grid>
      </Container>
            <img
              src={Logo}
              alt={'RojBau-Logo'}
              loading="lazy"
              style={{width:200}}
            /> 
    </Typography>
  );
}