import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import ImgB from '../img/productCurvyLines.png'
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import Toolbar from "@mui/material/Toolbar";
import { Button } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';

const backgroundImage = ImgB;

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: 'default',
  color: 'secondary.main',
  fontWeight: 'medium',
};

const icon = {
    color:'#ffc300'
  };


function Konnen() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', bgcolor: 'secondary.light', overflow: 'hidden' }}
      style={{backgroundColor:'white'}}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        
      >
        <Box
          component="img"
          src={backgroundImage}
          alt="curvy lines"
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: -180,
            opacity: 0.5,
          }}
        />

        <div>
          <Grid container spacing={5}>

            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}><WbIncandescentIcon style={icon} /></Box>
                <Typography variant="h5" align="center">
                Fliesenverlegung
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}><WbIncandescentIcon style={icon}/></Box>
                <Typography variant="h5" align="center">
                Großbaustellen
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}><WbIncandescentIcon style={icon}/></Box>
                <Typography variant="h5" align="center">        	
                Mosaikfliesen
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
            <Box sx={item}>
                <Box sx={number}><WbIncandescentIcon style={icon}/></Box>
                <Typography variant="h5" align="center"> 	
                Großformatfliesen
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
            <Box sx={item}>
                <Box sx={number}><WbIncandescentIcon style={icon}/></Box>
                <Typography variant="h5" align="center">
                Badsanierung
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
            <Box sx={item}>
                <Box sx={number}><WbIncandescentIcon style={icon}/></Box>
                <Typography variant="h5" align="center">
                Sanierung
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
            <Box sx={item}>
                <Box sx={number}><WbIncandescentIcon style={icon}/></Box>
                <Typography variant="h5" align="center">
                Trockenbau
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
            <Box sx={item}>
                <Box sx={number}><WbIncandescentIcon style={icon}/></Box>
                <Typography variant="h5" align="center">
                Planung
                </Typography>
              </Box>
            </Grid>

          </Grid>
          <Toolbar />
          <Typography color="inherit" align="left" variant="h7" marked="center">
          Ob im Bad, in der Küche oder in einem anderen Zimmer: erst die passenden Fliesen machen einen Raum zu einem Ort, an dem man sich gern aufhält. Entscheidend dabei ist, dass die Wahl der Fliesen nicht nur auf die Funktionalität abzielt. 
          Schließlich soll der Raum durch die Fliesen nicht nur praktisch nutzbar sein, wenn es gleichzeitig genauso gut möglich ist, ihn einladend und warm zu gestalten.
          </Typography>
          <Toolbar />
          <Typography color="inherit" align="left" variant="h7" marked="center">
          An dieser Stelle kommen wir ins Spiel. Wir, das sind DIE FLIESENLEGER ROJBAU mit mehr als 15 Jahren Berufserfahrung.
          Gemeinsam mit Ihnen erarbeiten wir ein stimmiges und individuelles Konzept, das zu Ihren Ansprüchen passt.
          Ob Sanierung, Trockenbau Großbaustellen oder Planung : DIE FLIESENLEGER ROJBAU möchten, dass Sie sich nach getaner Arbeit ganz einfach rundum wohlfühlen.
          </Typography>
        </div>
         <Button
          color="secondary"
          size="large"
          component="a"
          href="tel:+4917684752988"
          sx={{ mt: 8 }}
          variant="outlined" 
          startIcon={<CallIcon/>}
        >
            +49 17684752988
        </Button>

        <Button
          color="secondary"
          size="large"
          component="a"
          href="mailto:roj.bau49@gmail.com"
          sx={{ mt: 8 }}
          variant="outlined" 
          startIcon={<EmailIcon/>}
        >
            roj.bau49@gmail.com
        </Button> 

      </Container>
    </Box>
  );
}

export default Konnen;