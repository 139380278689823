import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Logo from '../img/logo.png'

export default function appbar() {
  return (
    <AppBar component="nav" style={{
      background: "rgba(255, 255, 255, 0.21)",
      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
      backdropFilter: "blur(5px)",
      WebkitBackdropFilter: "blur(5px)",
      border: "1px solid rgba(255, 255, 255, 0.3)"
    }}>
      <div style={{
        display:'flex',
        justifyContent:'center',
        flexDirection:'column',
        alignItems:'center',
      }}>
      <Toolbar>
              <img
              src={Logo}
              alt={'RojBau-Logo'}
              loading="lazy"
              style={{width:200}}
            />
      </Toolbar>
      </div>
    </AppBar>
  )
}

