import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "./components/appbar";
import Start from './components/start';
import Konnen from './components/konnen';
import ImgList from './components/imgList';
import Footer from './components/footer';

function App() {
  return (
    <div> 
    <Box sx={{ display: "flex" }}>
    <CssBaseline />
    <AppBar/>
    <Box component="main" sx={{ p: 0 }}>
      <Toolbar />
      <Start/>
      <Konnen/>
      <ImgList/>
      <Footer/>
    </Box>
  </Box>
  </div>
  );
}

export default App;
