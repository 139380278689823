import React,{useState} from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import A1 from "../img/anas.jpg"
import A2 from "../img/roj (1).jpg"
import A3 from "../img/roj (2).jpg"
import A4 from "../img/roj (4).jpg"
import A5 from "../img/roj (5).jpg"
import A6 from "../img/roj (6).jpg"
import A7 from "../img/roj (7).jpg"
import A8 from "../img/roj (8).jpg"
import A9 from "../img/roj (9).jpg"
import A10 from "../img/roj (10).jpg"
import A11 from "../img/roj (11).jpg"
import A12 from "../img/roj (12).jpg"
import A13 from "../img/roj (13).jpg"
import A14 from "../img/roj (14).jpg"
import A15 from "../img/roj (15).jpg"
import A16 from "../img/roj (16).jpg"
import A17 from "../img/roj (17).jpg"
import A18 from "../img/roj (18).jpg"
import A19 from "../img/roj (19).jpg"
import A20 from "../img/roj (20).jpg"

import Backdrop from '@mui/material/Backdrop';

export default function MasonryImageList() {

  const [open, setOpen] = useState(false);
  const [item, setitem] = useState('');

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = (i) => {
    setOpen(!open);
    setitem(i)
  };


  return (
    <div>
<Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={open}
  onClick={handleClose}
>

            <img
              src={`${item}?w=248&fit=crop&auto=format`}
              srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={'RojBau'}
              loading="lazy"
              onClick={handleToggle}
              style={{ maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }}
            />

</Backdrop>


    <Box sx={{ overflowY: 'scroll' }}>
      <ImageList variant="masonry" cols={3} gap={8}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={'RojBau'}
              loading="lazy"
              onClick={()=>handleToggle(item.img)}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box></div>
  );
}

const itemData = [
  {
    img: A1,
  },
  {
    img: A2,
  },
  {
    img: A3,
  },
  {
    img: A4,
  },
  {
    img: A5,
  },
  {
    img: A6,
  },
  {
    img: A7,
  },
  {
    img: A8,
  },
  {
    img: A9,
  },
  {
    img: A10,
  },
  {
    img: A11,
  },
  {
    img: A12,
  },
  {
    img: A13,
  },
  {
    img: A14,
  },
  {
    img: A15,
  },
  {
    img: A16,
  },
  {
    img: A17,
  },
  {
    img: A18,
  },
  {
    img: A19,
  },
  {
    img: A20,
  },
];