import ProductHeroLayout from "./s";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import F from '../img/f.jpg'

const backgroundImage = F;

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9',
        backgroundPosition: 'center',
      }}
    >
      <Toolbar />
      <Typography color="inherit" align="center" variant="h4" marked="center">
      ROJBAU HEISST
      </Typography>
      <Typography color="inherit" align="center" variant="h4" marked="center">
      SIE WILLKOMMEN
      </Typography>
      <Toolbar />
      <Toolbar />
    </ProductHeroLayout>
  );
}